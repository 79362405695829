export const setMetadata = ({
  pageTitle,
  pageDescription,
  keywords,
  imageUrl,
  robots,
}: {
  pageTitle?: string
  pageDescription?: string
  keywords?: string
  imageUrl?: string
  robots?: string
}) => {
  const route = useRoute()
  const config = useRuntimeConfig()

  const env = config.public.SENTRY_ENVIRONMENT

  const metadataTitle = pageTitle ?? 'Hoap'
  const title = `Hoap • ${metadataTitle}`
  const description = pageDescription ?? 'Wellbeing and Performance elevated'
  const url = config.APP_URL + route.fullPath
  const metadataImageUrl = imageUrl
    ? imageUrl.charAt(0) === '/'
      ? config.APP_URL + imageUrl
      : imageUrl
    : config.APP_URL + '/img/app-icon.png'

  useHead({
    title,
    meta: [
      {
        name: 'description',
        content: description,
      },
      {
        name: 'keywords',
        content: keywords,
      },
      {
        name: 'robots',
        content: env === 'development' ? 'noindex, nofollow' : robots ?? 'index',
      },
      {
        property: 'og:title',
        content: title,
      },
      {
        property: 'og:description',
        content: description,
      },
      {
        property: 'og:image',
        content: metadataImageUrl,
      },
      {
        property: 'twitter:card',
        content: 'summary_large_image',
      },
      {
        property: 'twitter:title',
        content: title,
      },
      {
        property: 'twitter:description',
        content: description,
      },
      {
        property: 'twitter:url',
        content: url,
      },
      {
        property: 'twitter:image',
        content: metadataImageUrl,
      },
    ],
  })
}
