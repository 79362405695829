<template>
  <NuxtLink v-if="url" :to="url" :target="target" :class="classes">
    {{ label }}
  </NuxtLink>
  <button v-else v-bind="$attrs" :class="classes">
    {{ label }}
  </button>
</template>

<script lang="ts" setup>
  import { computed } from 'vue'

  const props = defineProps({
    label: {
      type: String,
      default: 'Read More',
    },
    inverted: Boolean,
    variant: String,
    url: String,
    external: Boolean,
    disabled: Boolean,
  })

  const target = computed(() => (props.external ? '_blank' : '_self'))

  const variantStyles = computed(() => {
    switch (props.variant) {
      case 'large':
        return 'px-8 py-6 text-lg rounded-full'
    }

    return 'px-8 py-3 text-sm rounded-full'
  })

  const disabledClasses = 'cursor-disabled bg-gray-400'

  const classes = computed(() => {
    return [
      'transition-quick border border-white ',
      props.inverted
        ? 'text-white bg-transparent hover:bg-white hover:text-black'
        : 'text-black bg-white hover:bg-transparent hover:text-white',
      variantStyles.value,
      props.disabled ? disabledClasses : '',
    ]
  })
</script>
